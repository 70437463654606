<template>
  <ValidationObserver v-if="isInputProcess" v-slot="{ invalid }">
    <div
      class="border rounded-3xl shadow-xl py-8 w-full sm:w-4/5 lg:w-3/5 grid justify-items-center mx-auto"
    >
      <div class="w-3/4 lg:w-1/2">
        <!-- <ValidationProvider name="username" rules="required">
                    <div class="flex">
                        <font-awesome-icon class="input-field-icon" icon="fa-solid fa-user" />
                        <input v-model="form.phone" placeholder="Phone number" class="input-field" />
                    </div>
                </ValidationProvider> -->

        <ValidationProvider
          name="phone"
          :rules="{ required: true, regex: /^[0-9]+$/, min: 2 }"
        >
          <div class="flex">
            <font-awesome-icon
              class="input-field-icon"
              icon="fa-solid fa-mobile-screen-button"
            />
            <vue-country-code
              @onSelect="selectCountry"
              defaultCountry="ID"
              enabledCountryCode
              :enabledFlags="false"
              :onlyCountries="['MY', 'AU', 'ID', 'PH', 'SG', 'TH']"
              style="border: none"
            ></vue-country-code>
            <div class="text-primary text-2xl">|</div>
            <input
              v-model="form.phone"
              placeholder="Phone number"
              class="input-field"
              @keypress.enter="validateForgotPassword"
            />
          </div>
        </ValidationProvider>
        <ValidationProvider class="block mt-6" name="password" rules="required">
          <div class="flex">
            <font-awesome-icon
              class="input-field-icon"
              icon="fa-solid fa-lock"
            />
            <input
              v-model="form.password"
              :type="showPassword ? 'text' : 'password'"
              placeholder="New Password"
              @keypress.enter="validateForgotPassword"
              class="input-field"
              pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
            />
            <div
              v-on:click="showPassword = !showPassword"
              class="cursor-pointer text-primary flex items-center"
            >
              <font-awesome-icon v-if="showPassword" icon="fa-solid fa-eye" />
              <font-awesome-icon v-else icon="fa-solid fa-eye-slash" />
            </div>
          </div>
        </ValidationProvider>
        <ValidationProvider
          class="block mt-6"
          name="confirm_password"
          rules="required"
        >
          <div class="flex">
            <font-awesome-icon
              class="input-field-icon"
              icon="fa-solid fa-lock"
            />
            <input
              v-model="form.confirmPassword"
              :type="showConfirmPassword ? 'text' : 'password'"
              autocomplete="new-password"
              placeholder="Confirm New Password"
              @keypress.enter="validateForgotPassword"
              class="input-field"
              pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
            />
            <div
              v-on:click="showConfirmPassword = !showConfirmPassword"
              class="cursor-pointer text-primary flex items-center"
            >
              <font-awesome-icon
                v-if="showConfirmPassword"
                icon="fa-solid fa-eye"
              />
              <font-awesome-icon v-else icon="fa-solid fa-eye-slash" />
            </div>
          </div>
          <div
            class="font-semibold text-sm text-right mt-2"
          >
            <span class="cursor-pointer" @click="$router.push({ name: 'Login' })">Back to Login ?</span>
          </div>
        </ValidationProvider>
      </div>
    </div>
    <div class="flex justify-center mt-8">
      <button
        @click="validateForgotPassword"
        class="button-submit text-lg px-20"
      >
        Next
      </button>
    </div>
  </ValidationObserver>
  <div v-else>
    <div
      class="border rounded-3xl shadow-xl pt-4 pb-6 w-4/5 md:w-3/5 lg:w-2/5 mx-auto"
    >
      <div class="text-center text-xl text-gray-800 font-bold">
        Please verify your account
      </div>
      <div class="text-center text-gray-500 font-medium mt-2">
        We have sent a verification code to {{ formattedPhone }}.
      </div>
      <v-otp-input
        class="flex justify-center w-4/5 sm:w-3/5 mx-auto mt-6 gap-2"
        :num-inputs="6"
        separator=""
        input-type="number"
        input-classes="w-10 h-10 border border-gray-400 rounded-lg text-center"
        @on-change="otp = $event"
        @on-complete="handleOnComplete"
      />
    </div>
    <button
      @click="verify"
      :disabled="isInvalidOtp"
      class="bg-primary text-white hover:bg-primary-darker disabled:bg-gray-200 disabled:text-gray-500 disabled:cursor-default font-semibold py-2 px-12 block mx-auto rounded-lg mt-8"
    >
      Verify
    </button>
    <div class="text-center text-sm mt-8">
      <span class="font-medium">Didn't receive an OTP ?</span>
      <span @click="resendOtp" class="cursor-pointer font-bold">
        Resend OTP</span
      >
      <span v-show="sendOtpInCooldown">
        in
        <span class="text-red-500 font-semibold"
          >{{ otpTimerMinutes }} : {{ otpTimerSeconds }}</span
        >
      </span>
    </div>
    <div
      @click="resetForgotPassword"
      class="text-center cursor-pointer text-sm mt-4"
    >
      Cancel
    </div>
  </div>
</template>

<script>
export default {
  name: "ForgotPassword",

  data() {
    return {
      isInputProcess: true,
      form: { phone: null, phoneCountryCode: null, password: null, confirmPassword: null },
      showPassword: false,
      showConfirmPassword: false,
      dialCode: 62,
      formattedPhone: null,
      otp: "",
      otpTimer: 0,
    };
  },
  computed: {
    isInvalidOtp() {
      return this.otp.toString().length != 6;
    },
    otpTimerMinutes() {
      const val = Math.floor(this.otpTimer / 60);
      return val < 10 ? `0${val}` : val;
    },
    otpTimerSeconds() {
      const val = this.otpTimer - this.otpTimerMinutes * 60;
      return val < 10 ? `0${val}` : val;
    },
    sendOtpInCooldown() {
      return this.otpTimer > 0;
    },
  },
  methods: {
    handleOnComplete(value) {
      if (value.length == 6) {
        this.otp = value;
      }
    },
    setOtpTimer(second) {
      this.otpTimer = second;
      this.startOtpTimerCountdown();
    },
    startOtpTimerCountdown() {
      if (this.otpTimer > 0) {
        setTimeout(() => {
          this.otpTimer -= 1;
          this.startOtpTimerCountdown();
        }, 1000);
      }
    },
    selectCountry({ dialCode }) {
      this.dialCode = dialCode;
      this.form.phoneCountryCode = dialCode;
    },
    resetForgotPassword() {
      this.form.password = null;
      this.form.confirmPassword = null;
      this.isInputProcess = true;
    },
    validateForgotPassword() {
      if(this.form.phone == null || this.form.phone == ""){
        this.$toasted.global.error("Phone number cannot be empty");
        return;
      }

      if(this.form.password == null || this.form.password == ""){
        this.$toasted.global.error("New password cannot be empty");
        return;
      }
      
      // if(!this.form.password.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/)){
      //   this.$toasted.global.error("Password must contain 1 uppercase, 1 lowercase, 1 number and minimum 8 character");
      //   return;
      // }
      
      if(this.form.confirmPassword == null || this.form.confirmPassword == ""){
        this.$toasted.global.error("Cofirm password cannot be empty");
        return;
      }

      if(this.form.confirmPassword !== this.form.password){
        this.$toasted.global.error("Cofirm password didn't match");
        return;
      }
      this.forgotPassword();
    },
    async forgotPassword() {
      this.$store.commit("setOverlayLoading", true);

      if (this.dialCode == 62) {
        if (this.form.phone.slice(0, 1) == "0") {
          this.formattedPhone = this.form.phone;
        } else {
          this.formattedPhone = "0" + this.form.phone;
        }
      } else {
        this.formattedPhone = this.dialCode + this.form.phone;
      }
      // this.formattedPhone = this.form.phone;

      // const checkData = new FormData();
      // checkData.append("txtTelepon", this.formattedPhone);

      try {
        if (this.form.password != this.form.confirmPassword) {
          throw "Confirmation Password not same";
        }

        // const phoneResponse = await this.$http.post(
        //   `${this.$apiTripwe}/ic/jetski-android-new/cek_telepon.php`,
        //   checkData
        // );

        // if (phoneResponse.data != "success") {
        //   throw "Phone number not found";
        // }

        const otpResponse = await this.$http.post(
          `${this.$apiTripweWebV2}/users/forgot-password/validate`,
          {
            phone: this.form.phone,
            phone_country_code: this.form.phoneCountryCode,
            new_password: this.form.password,
            confirm_new_password: this.form.confirmPassword,
          }
        );

        if (otpResponse.data.status != 200 && otpResponse.data.status != 403) {
          throw otpResponse.data.message;
        }

        var curTime = this.$moment();
        var nextReq = this.$moment(otpResponse.data.data.next_request_at);
        var diff = nextReq.diff(curTime, 'seconds');
        if(otpResponse.data.status == 403){
          this.$toasted.global.error(otpResponse.data.message);
        }else{
          this.$toasted.global.success(otpResponse.data.message);
        }
        this.setOtpTimer(diff);
        this.isInputProcess = false;
      } catch (error) {
        this.$toasted.global.error(error);
      }

      this.$store.commit("setOverlayLoading", false);
    },
    async verify() {
      this.$store.commit("setOverlayLoading", true);

      try {
        const updatePass = await this.$http.post(
          `${this.$apiTripweWebV2}/users/forgot-password`,
          {
            phone: this.form.phone,
            phone_country_code: this.form.phoneCountryCode,
            new_password: this.form.password,
            confirm_new_password: this.form.confirmPassword,
            otp: this.otp,
          }
        );

        if (updatePass.data.status != "200") {
          throw updatePass.data.message;
        }

        this.$toasted.global.success("Your password has been updated");
        this.$router.push({ name: "Login" });
      } catch (error) {
        this.$toasted.global.error(error);
      }

      this.$store.commit("setOverlayLoading", false);
    },
    async resendOtp() {
      if (this.sendOtpInCooldown) {
        this.$toasted.global.error(
          "Please wait a moment before sending new OTP"
        );
        return;
      }

      this.$store.commit("setOverlayLoading", true);

      try {
        const response = await this.$http.post(
          `${this.$apiTripweWebV2}/notifications/send-otp`,
          {
            phone: this.form.phone,
            phone_country_code: this.form.phoneCountryCode,
            scope: "FORGOT_PASSWORD",
          }
        );

        if (response.data.status != 200 && response.data.status != 403) {
          throw response.data.message;
        }

        var curTime = this.$moment();
        var nextReq = this.$moment(response.data.data.next_request_at);
        var diff = nextReq.diff(curTime, 'seconds');
        if(response.data.status == 403){
          this.$toasted.global.error(response.data.message);
        }else{
          this.$toasted.global.success(response.data.message);
        }
        this.setOtpTimer(diff);
        this.isInputProcess = false;
      } catch (error) {
        this.$toasted.global.error(error);
      }

      this.$store.commit("setOverlayLoading", false);
    },
  },
};
</script>
